import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Snackbar, Alert } from "@mui/material";
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { theme_settings, palette_light, palette_dark } from "./MuiTheme.js";
import log from "loglevel";

import Navbar from "./Navbar.js";
import ResetPassword from "./user-authentication/ResetPassword.js";
import LandingPage from "./landing-page/LandingPage.js";
import AboutPage from "./AboutPage.js";
import PricingPage from "./PricingPage.js";
import SupportPage from "./SupportPage.js";
import StrategyDashboard from "./strategy-dashboard/StrategyDashboard.js";
import CookieBanner from "./CookieBanner.js";
import LoadingModal from "./LoadingModal.js";
import Profile from "./user-authentication/Profile.js";
import Signin from "./user-authentication/Signin.js";
import { defaultAccountContext, AccountContext } from "./../context/AccountContext.js";
import { whoAmI, updateAccountTheme } from "./../lib/account-ops.js";


class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"theme_mode" : "light",
			"current_theme" : createTheme(theme_settings),
			"context_data" : defaultAccountContext,
			"loading_msgs" : [],
			"old_theme" : null
		};

		this.updateAccountContext = this.updateAccountContext.bind(this);
		this.handleThemeOverride = this.handleThemeOverride.bind(this);
	}

	static propTypes = null;
	static contextType = null;

	componentDidMount() {
		const { REACT_APP_ENV } = process.env;

		log.debug("App componentDidMount");
		log.debug(process.env);
		log.info(`Environment detected: ${REACT_APP_ENV}`);

		whoAmI((err, context_data) => {
			if (err) {
				log.error(err);
				this.updateAccountContext({
					"snackbar_msg" : "Failed to get session details.",
					"snackbar_sev" : "error"
				});
				return;
			}

			// Determines which account context to use based on whoami.
			const { is_authenticated, membership_expiration } = context_data;
			const new_context = is_authenticated ? context_data : defaultAccountContext;
			const is_expired = membership_expiration > 0 && new Date().getTime() > membership_expiration;
			this.updateAccountContext({
				...new_context,
				"is_expired" : is_authenticated && is_expired,
				"is_loaded" : true,
				"setContext" : this.updateAccountContext
			}, this.handleThemeOverride);
		});
	}

	render() {
		const { context_data, theme_mode, current_theme } = this.state;
		const {
			is_authenticated,
			snackbar_msg, snackbar_sev,
			is_loading, loading_msg, customizations, setContext
		} = context_data;

		return (
			<BrowserRouter>
				<AccountContext.Provider value={context_data}>
					<ThemeProvider theme={current_theme} >
						<Navbar
							onDarkThemeToggle={() => {
								const new_mode = theme_mode === "light" ? "dark" : "light";
								log.debug(`Switching to [${new_mode}] mode.`);
								// Select the correct palette based on new mode.
								let new_theme = { ...theme_settings };
								if (new_mode === "light") {
									new_theme["palette"] = palette_light;
								} else if (new_mode === "dark") {
									new_theme["palette"] = palette_dark;
								}

								const cnvtTheme = t => {
									return {
										"primary" : t.palette.primary.main,
										"secondary" : t.palette.secondary.main,
										"success" : t.palette.success.main,
										"info" : t.palette.info.main,
										"warning" : t.palette.warning.main,
										"error" : t.palette.error.main,
										"background" : t.palette.background.main,
										"paper" : t.palette.background.paper,
										"font1" : t.palette.font.main,
										"font2" : t.palette.font.button,
										"mode" : new_mode
									};
								};

								new_theme = cnvtTheme(new_theme);
								updateAccountTheme(new_theme, err => {
									if (err) {
										log.error(err);
										return;
									}

									setContext({
										"customizations" : {
											...customizations,
											"theme" : new_theme,
										},
									}, this.handleThemeOverride);
								});
							}}
						/>

						<Snackbar
							anchorOrigin={{"vertical" : "top", "horizontal" : "right"}}
							autoHideDuration={5000}
							open={snackbar_msg && snackbar_msg !== ""}
							onClose={(evt, reason) => {
								if (reason !== "timeout") evt.preventDefault();
								else this.updateAccountContext({"snackbar_msg" : null});
							}}
						>
							<Alert severity={snackbar_sev}>{snackbar_msg}</Alert>
						</Snackbar>

						<LoadingModal
							is_open={is_loading}
							message={loading_msg}
						/>

						{/* Switch component will render the first Route which matches the path. */}
						<Switch className="body-container">
							{/* Homepage, currently just the dashboard. */}
							<Route path="/" exact component={LandingPage} />
							<Route path="/about" exact component={AboutPage} />
							<Route path="/pricing" exact component={PricingPage} />
							<Route path="/support" exact component={SupportPage} />

							{/**
							* Account settings. Rendering it this way ensures access
							* to this.props.location and this.props.history.
							*/}
							<Route path="/account" exact component={is_authenticated ? Profile : Signin} />
							<Route path="/account/reset-password" exact component={ResetPassword} />

							<Route path="/dashboard" component={StrategyDashboard}/>
						</Switch>

						{ is_authenticated && <CookieBanner /> }
					</ThemeProvider>
				</AccountContext.Provider>
			</BrowserRouter>
		);
	}

	/**
	 * Called whenever the user logs in, signs up, or has an account detail updated.
	 * accountDidUpdate is passed to components as "onAccountUpdate"
	 * 
	 * @param {*} context_data
	 * @returns N/A
	 */
	updateAccountContext(new_context_data, cb=null) {
		const { context_data, loading_msgs } = this.state;

		let new_loading_msgs = [ ...loading_msgs ];

		if (new_context_data["is_loading"] === true) {
			// Appends new message.
			const tmp = new_context_data["loading_msg"];

			log.debug(`Adding loading message [${tmp}]`);
			new_loading_msgs.push(tmp);
		} else if (new_context_data["is_loading"] === false) {
			// Removes last element of array, effectively a LIFO queue.
			let n = new_loading_msgs.length - 1;
			log.debug(`Removing loading message [${new_loading_msgs[0]}]`);
			new_loading_msgs.shift();
			// If there are still loading messages, that means we are still loading so don't reset.
			if (n > 0) {
				delete new_context_data["is_loading"];
				new_context_data["loading_msg"] = new_loading_msgs[0];
			}
		}
		/*if ("is_loading" in new_context) {
			const v1 = new_context["is_loading"];
			const v2 = new_context["loading_msg"];
			const idx = loading_msgs.indexOf(v2);

			let new_msgs = [...loading_msgs];
			if (v1) new_msgs.push(v2);
			else new_msgs.splice(idx, 1);

			this.setState({
				"loading_msgs" : new_msgs
			}, () => {
				delete new_context["is_loading"];
				delete new_context["loading_msg"];

				const n = this.state.loading_msgs.length;
				log.debug(`There are now ${n} loading messages.`);
				setContext({
					"is_loading" : n > 0,
					"loading_msg" : n > 0 ? new_msgs[n - 1] : null
				});
			});
		}*/

		this.setState({
			"context_data" : {...context_data, ...new_context_data},
			"loading_msgs" : new_loading_msgs
		}, () => {
			this.handleThemeOverride();
			if (cb) return cb();
		});
	}

	handleThemeOverride() {
		const { customizations } = this.state.context_data;
		const { old_theme } = this.state;
		if (old_theme && JSON.stringify(old_theme) === JSON.stringify(customizations.theme));

		const mode = customizations?.theme?.mode || "light"
		let palette = mode === "light" ? palette_light : palette_dark;

		// Convert between SQL version and MUI version.
		palette.primary.main = customizations.theme.primary;
		palette.secondary.main = customizations.theme.secondary;
		palette.success.main = customizations.theme.success;
		palette.info.main = customizations.theme.info;
		palette.warning.main = customizations.theme.warning;
		palette.error.main = customizations.theme.error;
		palette.background.main = customizations.theme.background;
		palette.background.paper = customizations.theme.paper;
		palette.font.main = customizations.theme.font1;
		palette.font.button = customizations.theme.font2;

		// Update the root elemnt with the new background color.
		const e = document.getElementsByTagName("html")[0];
		e["style"]["background-color"] = palette.background.main;

		this.setState({
			"theme_mode" : mode,
			"current_theme" : createTheme({...theme_settings, palette}),
			"old_theme" : customizations.theme
		});

		// Sets CSS variables based on MuiTheme
		const root_selector = document.querySelector(":root");
		root_selector.style.setProperty("--primary_color", customizations.theme.primary);
		root_selector.style.setProperty("--secondary_color", customizations.theme.secondary);
		root_selector.style.setProperty("--font_main_color", customizations.theme.font1);
		root_selector.style.setProperty("--navbar_fontsize", "0.9em");
		root_selector.style.setProperty("--navbar_height", "6vh");
		root_selector.style.setProperty("--body_height", "94vh");
		root_selector.style.setProperty("--footer_height", "0vh");
	}
}

export default App;
