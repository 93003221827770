import React from "react";
import PropTypes from "prop-types";

import async from "async";
import log from "loglevel";
import moment from "moment";

import {
	TextField, Button, Box, Autocomplete, Container, Paper,
	Typography, Tooltip, IconButton
} from "@mui/material";
import RefreshIcon from '@mui/icons-material/Refresh';
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterMoment}  from "@mui/x-date-pickers/AdapterMoment";

// Used for storing relevant strategy data across components.
import { StrategyContext } from "../../context/StrategyContext.js";
import "../../css/simulation-parameters.css";

import { msToDuration } from "../../lib/duration-ops.js";

import {
	submitStrategy, submitTemplate,
	createStrategy, getStrategySimulations,
	submitForwardTest, submitContinuousTemplate, stopContinuous,
	submitContinuousMutation, pollContinuousSimulations, getUserGroups
} from "../../lib/strategy-ops.js";

const base_period = (1000 * 60 * 60 * 24 * 180);
const continuous_poll_delay = (1000 * 30);
const lookback = (1000 * 60 * 60 * 24 * 90); // TODO: This MUST match the value inside the engine.
const number_of_strategies = 60;
const submit_interval = (1000 * 60 * 1);
const six_months = (1000 * 60 * 60 * 24 * 180);
const default_min_time = 1437665123000;
const default_max_time = 1658540514000;

class SimulationParameters extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			"simulation_data" : {
				"security" : null,
				"start_time": null,
				"stop_time" : null
			},
			"is_simulating" : false,
			"continuous_interval" : null
		};

		this.handleInputUpdate = this.handleInputUpdate.bind(this);
		this.handleSimulateStrategy = this.handleSimulateStrategy.bind(this);
		this.handleSimulateTemplate = this.handleSimulateTemplate.bind(this);
		this.handleContinuousStrategy = this.handleContinuousStrategy.bind(this);
		this.handleContinuousTemplate = this.handleContinuousTemplate.bind(this);
		this.handleContinuousMutation = this.handleContinuousMutation.bind(this);
		this.handleStopContinuous = this.handleStopContinuous.bind(this);
		this.updateContinuous = this.updateContinuous.bind(this);
		this.handleRefreshContinuous = this.handleRefreshContinuous.bind(this);
	}

	static contextType = StrategyContext;

	/**
	 * group_id is only provided
	 * 	Build a template payload, create dummy strategy, submit.
	 * 	Continuous template submission
	 * strategy_id is only provided
	 * 	Submit a single strategy for simulation.
	 * 	Forward testing
	 * group_id and strategy_id both provided
	 * 	Resubmit the "dummy" strategy's template payload.
	 * 	Continous template submission?
	 */
	static propTypes = {
		"strategy_id" : PropTypes.number,
		"group_id" : PropTypes.number,
		"is_valid" : PropTypes.bool,
		"demoSubmit" : PropTypes.func
	};

	componentDidMount() {
		log.debug("SimulationParameters componentDidMount");
		this.componentDidUpdate();
	}

	componentDidUpdate(prevProps) {
		log.debug("SimulationParameters componentDidUpdate");

		const {
			selected_leaf, security_pool,
			simulations, simulation_data, setContext
		} = this.context;
		const { strategy_id } = this.props;
		const { continuous_interval } = this.state;
		const new_strategy = prevProps?.strategy_id !== strategy_id;

		if (!security_pool || !security_pool.length) return;
		else if (!new_strategy && simulation_data.security) return;

		let security = null;
		let start_time = null;
		let stop_time = null;

		let is_strat = selected_leaf && strategy_id;
		const is_continuous = selected_leaf["is_continuous"];
		let sk = is_strat ? `strategy-${strategy_id}` : null;
		let has_simulations = is_strat && simulations && simulations[sk]?.length;
		if (has_simulations) {
			let sims = [ ...simulations[sk] ];
			let sim = sims.sort((a, b) => {return a.id - b.id})[0];

			security = security_pool.filter(s => s.security_id === sim.security_id)[0];
			start_time = Math.max(sim.start_time, security.start_time + lookback);
			stop_time = Math.min(sim.stop_time, security.stop_time);
		} else {
			security = security_pool.filter(s => s["symbol"] === "EUR_USD")[0];
			stop_time = security.stop_time;
			start_time = Math.max(security.start_time + lookback, stop_time - base_period);
		}

		const b = simulation_data.start_time !== start_time ||
					simulation_data.stop_time !== stop_time ||
					simulation_data.security?.id !== security.id;

		if (b) {
			setContext({"simulation_data" : {security, start_time, stop_time}});
		}

		// If the strategy is marked as "continuous", then start polling.
		if (!continuous_interval && is_strat && is_continuous) {
			this.setState({
				"continuous_interval" : setInterval(this.handleRefreshContinuous, continuous_poll_delay)
			});
		}
	}

	componentWillUnmount() {
		const { continuous_interval } = this.state;
		if (continuous_interval) clearInterval(continuous_interval);
	}

	render() {
		const {
			selected_leaf,
			security_pool,
			strategies,
			groups,
			simulations,
			membership_tier,
			setContext
		} = this.context;

		let {
			total_simulations,
			age_ms,
			submission_age,
		} = selected_leaf;
		const first_sim_ts = new Date().getTime() - submission_age;

		const { group_id, strategy_id, is_valid } = this.props;
		let { simulation_data } = this.context;
		const { security, start_time, stop_time } = simulation_data;

		// Determines boundaries for time selection based on the selected security.
		const min_time = security ? security.start_time + lookback : default_min_time;
		const max_time = security ? security.stop_time : default_max_time;

		let selected_start_time = start_time ? start_time : min_time;
		let selected_stop_time = stop_time ? stop_time : max_time;

		let available_start_date = new Date(min_time);
		let available_stop_date = new Date(max_time);

		/* Used so that the state is only updated once all date updates are added.*/
		let new_start_time = null;
		let new_stop_time = null;
		let save_timeout = null;
		const updateStartStopTime = (k, n) => {
			// TODO: find time zone offset, subtract it from N

			if (k === "start_time") {
				new_start_time = n;
				selected_start_time = n;
			} else {
				new_stop_time = n;
				selected_stop_time = n;
			}

			if (save_timeout) clearTimeout(save_timeout);
			save_timeout = setTimeout(() => {
				if (new_start_time) {
					simulation_data["start_time"] = new_start_time.valueOf();
				}
				if (new_stop_time) {
					simulation_data["stop_time"] = new_stop_time.valueOf();
				}

				setContext({ simulation_data });
			}, 3000); // Automatically save input after 3 seconds of inactivity.
		}

		const is_continuous = selected_leaf["is_continuous"];
		const is_forwardtest = !selected_leaf.payload?.params?.number_of_strategies;
		const is_mutation = is_continuous && !is_forwardtest;
		const group_type = group_id ? groups.filter(g => g.id === group_id)[0]["type"] : "custom";
		const is_template = group_type === "template";
		const is_dummy = strategy_id && is_template;

		let center_btn_text = is_continuous ? "Stop " : "Start ";
		let center_btn_desc = null;
		if (is_template) {
			center_btn_text += " Template";
			center_btn_desc = "Generate strategies based on specified guidelines.";
		} else if (is_mutation) {
			center_btn_text += " Mutating";
		} else {
			center_btn_text += " Forward Test";
			center_btn_desc = "Continuously monitor this strategy.";
		}
		if (is_continuous) center_btn_desc = null;

		return (
			<Box className="simulation-parameters">
				<Typography
					color="font.main"
					variant="h2"
				>Simulation Parameters</Typography>
				{
					is_continuous && membership_tier !== "demo" &&
					<Container
						component={Paper}
						sx={{"backgroundColor" : "secondary.main",}}
						className="continuous-stats flex-row-space-around"
					>
						<Typography
							color="font.button"
							variant="body3"
						>
						{total_simulations?.toLocaleString()} Simulations Completed
						</Typography>
						<Typography
							color="font.button"
							variant="body3"
						>
							{msToDuration(age_ms)} Since Previous Simulaiton
						</Typography>
						<Tooltip title={`${new Date(first_sim_ts).toLocaleString()}`}>
							<Typography
								color="font.button"
								variant="body3"
							>
								{msToDuration(submission_age)} Since First Simulation
							</Typography>
						</Tooltip>
						<IconButton
							size="small"
							onClick={this.handleRefreshContinuous}
						>
							<RefreshIcon
								className="continuous-stats-refresh"
								sx={{
									"typography" : "body1",
									"color" : "font.button"
								}}
							/>
						</IconButton>
					</Container>
				}


				<Container
					component={Paper}
					sx={{ "padding" : "10px" }}
				>
					{ /* Strategy Name Input  TODO: Move title input somehwere else.
					<label htmlFor="title">Strategy Title</label>
					<input type="text" name="title" onChange={this.handleInputUpdate} value={selected_leaf.title} />
					*/}


					<Box
						className="flex-col-center"
						sx={{
							"margin" : "0px 0px 10px 0px"
						}}
					>
						<Typography color="font.main"
							variant="body1"
							sx={{ "width" : "100%", "textAlign" : "center"}}
						>
							Security
						</Typography>

						<Autocomplete
							className="security-autocomplete"
							disabled={is_continuous}
							sx={{"width" : "200px", "marginBottom" : "10px"}}
							value={security ? {
								"id" : security.security_id,
								"label" : security.symbol
							} : null}
							isOptionEqualToValue={(a, b) => a.id === b.id}
							options={security_pool.map(s => {
								return {
									"label" : s.symbol,
									"id" : s.security_id
								}
							})}
							onChange={(evt, option) => {
								if (!option) return;
								this.handleInputUpdate({
									"target" : {
										"name" : "security", "value" : option?.id
									}
								});
							}}
							renderInput={params => {
								params["size"] = "small";
								params["InputProps"]["sx"] = {
									"typography" : "body2",
									"color" : "font.main"
								};
								params["InputLabelProps"]["sx"] = {"typography" : "body2"};
								return (<TextField {...params } />);
							}}
							renderOption={(params, option) => {
								return (
									<Typography
										color="font.main"
										{...params}
										variant="body2"
									>{option.label}</Typography>
								);
							}}
						/>

						{
							security && security.start_time && security.stop_time &&
							<Typography
								color="font.main"
								variant="body2"
								sx={{"textAlign" : "center"}}
							>
								Available Data: <br />
								{available_start_date.toLocaleString()}  -  {available_stop_date.toLocaleString()}
							</Typography>
						}
					</Box>

					{ /* Time Frame Input */ }
					<Box className="simulation-time-selector flex-row-space-around">
						<LocalizationProvider dateAdapter={AdapterMoment}>
							<Box className="flex-col-center">
								<Typography
									color="font.main"
									variant="body1"
									sx={{
										"width" : "100%",
										"textAlign" : "center"
									}}
								>Simulation Start</Typography>

								<DateTimePicker
									color="font.main"
									value={new moment(selected_start_time)}
									disabled={is_continuous}
									onChange={mm => {
										updateStartStopTime("start_time", mm);
									}}
									minDateTime={new moment(min_time)}
									maxDateTime={new moment(max_time)}
									slots={{
										"textField" : props => <TextField {...props} />
									}}
									slotProps={{
										"textField" : {
											"size" : "small",
											"InputProps" : {
												"sx" : {
													"color" : "font.main",
													"typography" : "body2"
												}
											}
										}
									}}
								/>
							</Box>

							<Box className="flex-col-center">
								<Typography color="font.main" variant="body1" sx={{ "width" : "100%", "textAlign" : "center"}}>Simulation Stop</Typography>
								<DateTimePicker
									value={new moment(selected_stop_time)}
									onChange={mm => {
										updateStartStopTime("stop_time", mm);
									}}
									disabled={is_continuous}
									minDateTime={new moment(min_time)}
									maxDateTime={new moment(max_time)}
									slots={{
										"textField" : props => <TextField {...props} />
									}}
									slotProps={{
										"textField" : {
											"size" : "small",
											"InputProps" : {
												"sx" : {
													"color" : "font.main",
													"typography" : "body2"
												}
											}
										}
									}}
								/>
							</Box>
						</LocalizationProvider>
					</Box>


					<Box
						className="flex-row-center"
						sx={{ "margin" : "20px 0px 0px 0px" }}
					>
						<Tooltip
							title={is_template ? "Submit this template for generation." : "Submit this strategy for simulation."}
						>
						<span>
						<Button
							onClick={() => {
								if (new_start_time) {
									let v = new_start_time.valueOf();
									if (v < min_time || v > max_time) {
										setContext({
											"snackbar_msg" : "Time out of bounds!",
											"snackbar_sev" : "error"
										});
										return;
									}
									simulation_data["start_time"] = v;
								}
								if (new_stop_time) {
									let v = new_stop_time.valueOf();
									if (v < min_time || v > max_time) {
										setContext({
											"snackbar_msg" : "Time out of bounds!",
											"snackbar_sev" : "error"
										});
										return;
									}
									simulation_data["stop_time"] = v;
								}

								setContext({
									simulation_data
								}, (strategy_id && !group_id) ?
										this.handleSimulateStrategy :
										this.handleSimulateTemplate
								);
							}}
							variant="contained"
							sx={{
								"color" : "font.button",
								"margin" : "20px 20px 20px 20px"
							}}
							disabled={
								is_valid === false ||
								selected_leaf.is_continuous || 
								selected_start_time < min_time ||
								selected_stop_time > max_time
							}
						>{is_template ? "Generate" : "Simulate"}</Button></span></Tooltip>
						{
							strategies && simulations && membership_tier && membership_tier !== "demo" &&

							<Tooltip title={center_btn_desc}><span><Button
								onClick={() => {
									if (new_start_time) {
										let v = new_start_time.valueOf();
										if (v < min_time || v > max_time) {
											setContext({
												"snackbar_msg" : "Time out of bounds!",
												"snackbar_sev" : "error"
											});
											return;
										}
										simulation_data["start_time"] = v;
									}
									if (new_stop_time) {
										let v = new_stop_time.valueOf();
										if (v < min_time || v > max_time) {
											setContext({
												"snackbar_msg" : "Time out of bounds!",
												"snackbar_sev" : "error"
											});
											return;
										}
										simulation_data["stop_time"] = v;
									}

									setContext({simulation_data}, () => {
										if (is_continuous) this.handleStopContinuous();
										else if (strategy_id && !group_id) this.handleContinuousStrategy();
										else this.handleContinuousTemplate();
									});
								}}
								variant="contained"
								sx={{
									"color" : "font.button",
									"margin" : "20px 20px 20px 20px"
								}}
								disabled={
									is_valid === false || 
									selected_start_time < min_time ||
									selected_stop_time > max_time
								}
							>{center_btn_text}</Button></span></Tooltip>
						}
						{
							strategies && simulations && membership_tier && membership_tier !== "demo" &&
							<Tooltip title="Generate new strategies similar to this one."><span><Button
								onClick={() => {
									if (new_start_time) {
										let v = new_start_time.valueOf();
										if (v < min_time || v > max_time) {
											setContext({
												"snackbar_msg" : "Time out of bounds!",
												"snackbar_sev" : "error"
											});
											return;
										}
										simulation_data["start_time"] = v;
									}
									if (new_stop_time) {
										let v = new_stop_time.valueOf();
										if (v < min_time || v > max_time) {
											setContext({
												"snackbar_msg" : "Time out of bounds!",
												"snackbar_sev" : "error"
											});
											return;
										}
										simulation_data["stop_time"] = v;
									}

									setContext({simulation_data}, this.handleContinuousMutation);
								}}
								variant="contained"
								sx={{
									"color" : "font.button",
									"margin" : "20px 20px 20px 20px"
								}}
								disabled={
									is_valid === false ||
									!strategy_id ||
									is_continuous ||
									is_dummy ||
									selected_start_time < min_time ||
									selected_stop_time > max_time
								}
							>Start Mutating</Button></span></Tooltip>
						}
					</Box>
				</Container>
			</Box>
		);
	}

	handleSimulateStrategy() {
		const { strategy_id, demoSubmit } = this.props;
		let {
			selected_leaf, simulations, simulation_data, setContext,
			setPage, sim_page_size, metric_sort_col, metric_sort_direction
		} = this.context;
		const { security, start_time, stop_time } = simulation_data;

		setContext({"is_loading" : true, "loading_msg" : "Simulating..."});

		async.series([
			async.apply(submitStrategy, strategy_id, security.security_id, start_time, stop_time),
			async.apply(getStrategySimulations, strategy_id, 1, sim_page_size, metric_sort_col, metric_sort_direction),
		], (err, results) =>{
			if (err) {
				console.error(err);
				return;
			}

			setContext({"is_loading" : false, "loading_msg" : null});

			if (simulations && !demoSubmit) {
				const sid = results[1]["simulations"].map(s=>s.id).filter(s=>!simulations[`strategy-${strategy_id}`].map(s=>s.id).includes(s))[0];
				simulations[`strategy-${strategy_id}`] = results[1]["simulations"];
				setContext({simulations, selected_leaf}, () => {
					const gid = selected_leaf["group_id"];
					setPage(`/dashboard/group-${gid}/strategy-${strategy_id}/simulation-${sid}`);
				});
			} else {
				const k = Object.keys(results[0])[0]
				const simdata = results[0][k]
				simdata["id"] = parseInt(k.split("-")[1]);
				demoSubmit(simdata);
			}
		});
	}
	handleSimulateTemplate() {
		const { group_id, strategy_id } = this.props;
		let {
			selected_leaf, strategies, simulation_data, setContext, setPage,
			sim_page_size, metric_sort_col, metric_sort_direction
		} = this.context;
		const { security, start_time, stop_time } = simulation_data;

		// If this is a dummy strategy, use the template stored in postgres. If it is a template, use the one saved from the UI.
		const template = strategy_id ? selected_leaf["payload"] : selected_leaf["template"];

		setContext({"is_loading" : true, "loading_msg" : "Simulating..."});

		let template_strategy = null;

		// TODO: Switch this to async.series?
		async.waterfall([
			// If this is a dummy strategy, use the template stored in postgres. If it is a template, use the one saved from the UI.
			strategy_id ? async.constant(selected_leaf) : async.apply(createStrategy, group_id),
			(strat, cb) => {
				template_strategy = strat;

				// Add strategy to context if a new one was created.
				if (!strategy_id) {
					strategies[`group-${group_id}`].push(strat);
				}


				return cb(null, strat.id);
			},
			async.apply(submitTemplate, security.security_id, start_time, stop_time, number_of_strategies, template),
			(submit_res, cb) => {
				// Update loading message while loading progresses.
				// TODO: Move this to the context?
				setContext({
					"snackbar_msg" : `Template Submitted, strategy=${template_strategy.id}`,
					"snackbar_sev" : "success"
				}, () => {
					cb(null, template_strategy.id);
				});
			},
			(strat_id, cb) => {
				getStrategySimulations(strat_id, 1, sim_page_size, metric_sort_col, metric_sort_direction, cb);
			}
		], (err, result) => {
			if (err) return log.error(err);

			// Adds new simulation to context.
			let { simulations, setContext } = this.context;
			simulations[`strategy-${template_strategy.id}`] = result["simulations"];
			setContext({simulations, "is_loading" : false, "loading_msg" : null});
			setPage(`/dashboard/group-${group_id}/strategy-${template_strategy.id}`);
		});
	}

	/**
	 * This method is a good candidate for the task to separate out the context
	 * operations into a different module
	 *
	 */
	updateContinuous(group_id, strategy_id, is_continuous, cb) {
		const { selected_leaf, strategies, setContext } = this.context;
		const k = `group-${group_id}`;

		// Filter out all records that match this strategy's ID.
		let idx = -1;
		let new_strategies = strategies[k].filter((s, i) => {
			const b = s.id !== strategy_id;
			if (!b) idx = i; // Save the index of the matching record.
			return b;
		});

		// Confirms only a single record was found.
		const len_diff = strategies[k].length - new_strategies.length;
		if (len_diff === 0) {
			selected_leaf["is_continuous"] = is_continuous;
			return setContext({selected_leaf}, cb);
		} else if (len_diff !== 1) {
			return cb(`Expected a single result, but found  ${len_diff}`);
		}

		// Extracts the single strategy record from the correct array
		let s = { ...strategies[k][idx] };
		// Makes desired modifications to object.
		s["is_continuous"] = is_continuous;
		// Re-saves the strategy into the larger list/object.
		strategies[k] = [...new_strategies, s];

		// Pulls updated continuous strategies.
		getUserGroups((err, res) => {
			if (err) return cb(err);

			strategies["continuous"] = res["continuous"];

			setContext({
				strategies,
				"selected_leaf" : {
					...selected_leaf,
					is_continuous
				}
			}, cb);
		});
	}

	// Forward test submission.
	handleContinuousStrategy() {
		const { selected_leaf, simulation_data } = this.context;
		const security_id = simulation_data.security.security_id;
		const start_time = simulation_data.start_time;
		const stop_time = simulation_data.stop_time;

		submitForwardTest(selected_leaf.id, security_id, start_time, stop_time, submit_interval, (err, resp) => {
			if (err) return log.error(err);

			if (!selected_leaf.payload) selected_leaf.payload = {};
			selected_leaf.payload["id"] = resp.request_id;
			selected_leaf.payload["is_forwardtest"] = true;

			this.updateContinuous(selected_leaf.group_id, selected_leaf.id, true, err => {
				if (err) {
					log.error(err);
					return;
				}
			});
		});
	}

	// continuous template submission.
	handleContinuousTemplate() {
		const {
			selected_leaf, strategies, simulation_data,
			setPage, setContext
		} = this.context;
		const { strategy_id, group_id } = this.props;

		setContext({
			"is_loading" : true,
			"loading_msg" : "Resubmitting...",
		});

		const security_id = simulation_data.security.security_id;
		const start_time = simulation_data.start_time;
		const stop_time = simulation_data.stop_time;

		// TODO: Make sure template is being read correctly.
		if (!selected_leaf.is_continuous && strategy_id) {
			const old_template = selected_leaf.payload?.params?.template;
			if (!old_template) {
				return setContext({
					"snackbar_msg" : "No previous template detected.",
					"snackbar_sev" : "error",
					"is_loading" : false,
					"loading_msg" : null,
				});
			}

			const onFail = (err) => {
				log.error(err);
				setContext({
					"snackbar_msg" : "Failed to Resubmit",
					"snackbar_sev" : "error",
					"is_loading" : false,
					"loading_msg" : null,
				});
			};

			// Resubmit an already exisitng dummy strategy.
			submitContinuousTemplate(strategy_id, security_id, start_time, stop_time, number_of_strategies, submit_interval, old_template, (err, resp) => {
				if (err) return onFail(err);

				if (!selected_leaf.payload) selected_leaf.payload = {};
				selected_leaf.payload["id"] = resp.request_id;

				this.updateContinuous(selected_leaf.group_id, selected_leaf.id, true, err => {
					if (err) return onFail(err);

					setTimeout(() => {
						setContext({
							"snackbar_msg" : "Resubmitted Template",
							"snackbar_sev" : "success",
							"is_loading" : false,
							"loading_msg" : null
						});
					}, 100);
				});
			});
		} else if (!selected_leaf.is_continuous && !strategy_id) {
			// Submit a template, first creating a dummy strategy.
			createStrategy(group_id, (err1, res1) => {
				if (err1) return log.error(err1);

				submitContinuousTemplate(res1.id, security_id, start_time, stop_time, number_of_strategies, submit_interval, selected_leaf["template"], (err, res2) => {
					if (err) return log.error(err);

					res1["payload"] = res2["payload"];
					strategies[`group-${selected_leaf.id}`].push(res1);

					setContext({
						strategies,
						"is_loading" : false,
						"loading_msg" : null
					}, () => {
						this.updateContinuous(group_id, res1.id, true, err => {
							if (err) {
								log.error(err);
								return;
							}

							setPage(`/dashboard/group-${selected_leaf.id}/strategy-${res1.id}`);
						});
					});
				});
			});
		}
	}

	handleStopContinuous() {
		const { selected_leaf } = this.context;
		const request_id = selected_leaf.payload.id;

		stopContinuous(request_id, err => {
			if (err) return log.error(err);

			selected_leaf.payload["is_forwardtest"] = false;
			this.updateContinuous(selected_leaf.group_id, selected_leaf.id, false, err => {
				if (err) {
					log.error(err);
					return;
				}
			});
		});
	}


	/* NOTE: This operation is only valid for strategies. */
	handleContinuousMutation() {
		const { strategy_id } = this.props;
		const { selected_leaf, simulation_data } = this.context;
		const { start_time, stop_time } = simulation_data;

		const security_id = simulation_data.security.security_id;

		submitContinuousMutation(strategy_id, security_id, start_time, stop_time, number_of_strategies, submit_interval, (err, resp) => {
			if (err) return log.error(err);
			if (!selected_leaf.payload) selected_leaf.payload = {};
			selected_leaf.payload["id"] = resp.request_id;

			this.updateContinuous(selected_leaf.group_id, selected_leaf.id, true, err => {
				if (err) {
					log.error(err);
					return;
				}
			});
		});
	}

	/**
	 * Updates the state with new strategy input. This funtion ensures that the
	 * strategy stored in the state updated on each edit.
	 *
	 * @param {*} evt The event that was triggered for the updated field.
	 */
	handleInputUpdate(evt, cb=null) {
		const { name, value } = evt.target;
		let { simulation_data } = this.state;
		const { security_pool, setContext } = this.context;

		switch(name) {
			case "start_time":
			case "stop_time":
				if (value) {
					simulation_data[name] = value.valueOf();
					simulation_data[`orig_${name}`] = value;
				} else {
					simulation_data[name] = null;
				}

				setContext({ simulation_data }, () => {
					// Checks if a callback was provided specifically for janky start/stop time patch.
					if (cb) return cb();
				});
				break;
			case "security":
				// TODO: Check if this is valid?
				let ns = security_pool.filter(sec => sec.security_id === value);
				if (ns.length === 0) ns = security_pool;
				simulation_data["security"] = ns[0];
				simulation_data["start_time"] = Math.max(ns[0].start_time + lookback, ns[0].stop_time - six_months);
				simulation_data["stop_time"] = ns[0].stop_time;
				simulation_data[`orig_security`] = ns[0];
				setContext({ simulation_data });
				break;
			default:
				log.error(`Unexpected name=${name}, value=${value}`);
				break;
		}
	}

	handleRefreshContinuous() {
		let {
			selected_leaf, simulations, setContext, metric_sort_col,
			metric_sort_direction, sim_page_size
		} = this.context;
		const leaf_id = selected_leaf["id"];

		// Moved from updateStrategyContext
		// Retreives all simulations for this strategy, as well as its queue status.
		getStrategySimulations(leaf_id, 1, sim_page_size, metric_sort_col, metric_sort_direction, (err, res) => {
			if (err) return log.error(err);

			simulations[`strategy-${leaf_id}`] = res["simulations"];
			selected_leaf["total_simulations"] = res["total"];
			selected_leaf["last_refresh_time"] = new Date();
			if (!selected_leaf["is_continuous"]) {
				setContext({selected_leaf, simulations});
				return;
			}

			pollContinuousSimulations(leaf_id, (err, res) => {
				if (err) return log.error(err);

				const { age_ms, submission_age } = res;

				selected_leaf["age_ms"] = age_ms;
				selected_leaf["submission_age"] = submission_age;

				setContext({selected_leaf, simulations});
			});
		});
	}
}

export default SimulationParameters;
