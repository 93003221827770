import axios from "axios";
import log from "loglevel";
import async from "async";

import { whoAmI } from "./account-ops.js";
import { msToDuration } from "./duration-ops.js";

const {
	REACT_APP_API_URL,
	REACT_APP_MAX_RETRIES,
	REACT_APP_HTTP_TIMEOUT
} = process.env;

/**
 * TODO: Test if this does anything.
 * Test if this plays nicely with CORS.
 */
const RETRY_DELAY = 5000;

/**
 * Used for controlling the various polls to the engine.
 * Arbitrarily set to 5000ms for now.
 */
const ENGINE_POLL_INTERVAL = 5000;
export const POLL_INTERVAL = ENGINE_POLL_INTERVAL;

export const STRATEGY_HEADERS = [
	{
		"id" : 0,
		"label" : "Strategy ID",
		"calculate" : s => s["id"]
	},
	{
		"id" : 1,
		"label" : "Title",
		"calculate" : s => s["title"]
	},
	{
		"id" : 2,
		"label" : "Continuous",
		"calculate" : s => s["is_continuous"] ? "Yes" : "No"
	},
	{
		"id" : 3,
		"label" : "Total Simulations",
		"calculate" : s => s["total_simulations"]?.toLocaleString() || 0
	},
	{
		"id" : 4,
		"label" : "Last Simulated At",
		"calculate" : s => (s["last_simulated_at"] === "Never" ? s["last_simulated_at"] : new Date(s["last_simulated_at"]).toLocaleString())
	},
	{
		"id" : 5,
		"label" : "Created At",
		"calculate" : s => { return new Date(s["created_at"]).toLocaleString() }
	}
];

/**
 * TODO:
 *	add title field to strategy creation
 *	make this field editable like other strategy details
 *	have this title populate the strategy list.
 */
const DEFAULT_NEW_STRATEGY = {
	"title" : "Untitled Strategy",
	"stop_loss" : 0.0030,
	"take_profit" : 0.0090,
	"trailing_stop_loss" : 0.0,
	"spread_limit" : 0.0010,
	"long_only" : false,
	"entrance_signals" : [
	{
		"title" : "MACD Cross Above",
		"indicator_title" : "Moving Average Convergence Divergence",
		"direction" : "L",
		"granularity" : 14400000,
		"parameters" : [12, 26, 9]
	},
	{
		"title" : "MACD Cross Below",
		"indicator_title" : "Moving Average Convergence Divergence",
		"direction" : "S",
		"granularity" : 14400000,
		"parameters" : [12, 26, 9]
	}
	],
	"exit_signals" : [
	{
		"title" : "MACD Cross Above",
		"indicator_title" : "Moving Average Convergence Divergence",
		"direction" : "L",
		"granularity" : 14400000,
		"parameters" : [12, 26, 9]
	},
	{
		"title" : "MACD Cross Below",
		"indicator_title" : "Moving Average Convergence Divergence",
		"direction" : "S",
		"granularity" : 14400000,
		"parameters" : [12, 26, 9]
	}
	]
};

export const DEFAULT_NEW_TEMPLATE = {
	"stop_loss": {
		"min": 0.0005,
		"max": 10.0,
		"step": 0.0001
	},
	"take_profit": {
		"min": 0.0005,
		"max": 10.0,
		"step": 0.0001
	},
	"trailing_stop_loss": {
		"min": 0.0005,
		"max": 0.9999,
		"step": 0.0001
	},
	"spread_limit": {
		"min": 0.0005,
		"max": 10.0,
		"step": 0.0001
	},
	"entrance_signals": [
		{
			"title": "MACD Cross Above",
			"indicator_title": "Moving Average Convergence Divergence",
			"optional": false,
			"direction": {
				"options": [
					"L",
					"S"
				]
			},
			"granularity": {
				"options": [
					14400000
				]
			},
			"parameters": {
				"P1 (Fast Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P2 (Slow Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P3 (Smoothing Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				}
			}
		},
		{
			"title": "MACD Cross Below",
			"indicator_title": "Moving Average Convergence Divergence",
			"optional": false,
			"direction": {
				"options": [
					"L",
					"S"
				]
			},
			"granularity": {
				"options": [
					14400000
				]
			},
			"parameters": {
				"P1 (Fast Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P2 (Slow Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P3 (Smoothing Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				}
			}
		}
	],
	"exit_signals": [
		{
			"title": "MACD Cross Above",
			"indicator_title": "Moving Average Convergence Divergence",
			"required_parameters": 3,
			"optional": false,
			"direction": {
				"options": [
					"L",
					"S"
				]
			},
			"granularity": {
				"options": [
					14400000
				]
			},
			"parameters": {
				"P1 (Fast Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P2 (Slow Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P3 (Smoothing Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				}
			}
		},
		{
			"title": "MACD Cross Below",
			"indicator_title": "Moving Average Convergence Divergence",
			"required_parameters": 3,
			"optional": false,
			"direction": {
				"options": [
					"L",
					"S"
				]
			},
			"granularity": {
				"options": [
					14400000
				]
			},
			"parameters": {
				"P1 (Fast Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P2 (Slow Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				},
				"P3 (Smoothing Length)": {
					"min": 2,
					"max": 50,
					"step": 1
				}
			}
		}
	]
};

let api_token = null;
let api_token_expiration = 0;
const getAuthToken = cb => {
	/**
	 * First checks if a token has already been generated that hasn't
	 * expired yet.
	 */
	let n = new Date().getTime();
	let d = api_token_expiration - n;
	if (api_token !== null && d > 0) {
		log.debug(`Current A.P.I. token still valid for ${msToDuration(d)}.`);
		return cb(null, api_token);
	}


	// Performs HTTP call.
	log.debug("Requesting new A.P.I. token.");
	whoAmI((err, me) => {
		if (err) return cb(err);

		// Sets the global auth token + expiration for future use.
		api_token = me["api_token"];
		api_token_expiration = me["api_token_expiration"];
		if (!api_token) {
			document.location.href = "/account";
			return;
			// return cb("Unauthenticated");
		}

		n = new Date().getTime();
		d = api_token_expiration - n;
		log.debug(`New A.P.I. token valid for ${msToDuration(d)}.`);

		return cb(null, api_token);
	});
}

/**
 * If true, uses demo token for all calls.
 * If false, reset auth.
 */
export const setAuthToken = (tok, expiry) => {
	// Updates global auth.
	api_token = tok;
	api_token_expiration = expiry;

	log.debug(`Set api_token=${api_token}`);
}

/**
 * Provides a generic method for calling the API so we don't use 50 different
 * axios() calls.
 *
 * TODO: This will also be where the API authentication occurs once it is
 * implemented?
 */
const callApi = (method, resource_url, params, data, cb, disable_auth = false, retries = 0) => {
	const url = REACT_APP_API_URL + resource_url;
	const timeout = REACT_APP_HTTP_TIMEOUT;
	let called_cb = false;

	// Doesn't pass username/password, depends on session cookie.
	getAuthToken((err, token) => {
		if (err && !disable_auth) return cb(err);

		const headers = !disable_auth ? { "Authorization" : `Bearer ${token}` } : {};

		axios({
			method, url, params, headers, data, timeout
		}).then(res => {
			/*
			 * TODO:
			 * Is there any extra processing we should do on the
			 * response?
			 */
			called_cb = true;
			return cb(null, res.data);
		}).catch(err => {
			log.error(err);
			const retryCall = async.apply(setTimeout, () => {
				callApi(method, resource_url, params, data, cb, disable_auth, retries + 1);
			}, RETRY_DELAY);

			if (called_cb) return;
			else if (retries >= REACT_APP_MAX_RETRIES) return cb(err);
			else return retryCall();
		});
	});
}

export const createGroup = (group_title, group_type, cb) => {
	callApi("PUT", "/group", {group_title, group_type}, null, cb);
}

export const getUserGroups = (cb) => {
	callApi("GET", "/group", null, null, cb);
}

export const updateTemplate = (group_id, template, cb) => {
	callApi("PATCH", `/group/${group_id}`, null, template, cb);
}

export const deleteGroup = (group_id, cb) => {
	callApi("DELETE", `/group/${group_id}`, null, null, cb);
}

/**
 * This functions should create the new postgres record. The strategy should
 * fallback on a default value.
 */
export const createStrategy = (group_id, cb) => {
	callApi("PUT", "/strategy", {group_id}, DEFAULT_NEW_STRATEGY, (err, d) => {
		if (err)
			return cb(err);
		else if (d.strategy_id === -1)
			return cb("There was an error while inserting the strategy.");

		const o = {
			"id" : d.strategy_id,
			group_id,
			...DEFAULT_NEW_STRATEGY
		}

		return cb(null, o);
	});
}
export const getGroupStrategies = (group_id, page, page_size, cb) => {
	callApi("GET", "/strategy", {group_id, page, page_size}, null, (err, d) => {
		if (err) return cb(err);

		return cb(null, d);
	});
}

export const getSingleStrategy = (strategy_id, cb) => {
	callApi("GET", `/strategy/${strategy_id}`, null, null, cb);
}

export const updateStrategy = (strat, cb) => {
	callApi("PATCH", `/strategy/${strat.id}`, null, strat, cb);
}

export const deleteStrategy = (id, cb) => {
	callApi("DELETE", `/strategy/${id}`, null, null, cb);
}

export const getRequestMetrics = (request_id, cb) => {
	callApi("GET", `/metric/request/${request_id}`, null, null, cb);
}

export const getStrategyMetrics = (strategy_id, cb) => {
	callApi("GET", `/metric/strategy/${strategy_id}`, null, null, cb);
}

export const getMetricsBySimulationId = (simulation_id, cb) => {
	callApi("GET", `/metric/simulation/${simulation_id}`, null, null, cb);
}

export const getStrategySimulations = (strategy_id, page, page_size, sort, direction, cb) => {
	callApi("GET", "/simulation", {strategy_id, page, page_size, sort, direction}, null, cb);
}

export const getSingleSimulation = (simulation_id, cb) => {
	callApi("GET", `/simulation/${simulation_id}`, null, null, cb);
}

export const getAllSecurities = (cb) => {
	callApi("GET", "/security", null, null, (err, d) => {
		return cb(err, d ? d.securities : null);
	});
}

export const getIndicatorPool = cb => {
	callApi("GET", "/indicator", null, null, cb);
}

const pollAssemble = (request_id, cb) => {
	callApi("GET", "/indicator/assemble", {request_id}, null, cb);
}

export const assembleStrategy = (simulation_id, cb) => {
	callApi("POST", `/indicator/assemble`, {simulation_id}, null, (err, d) => {
		if (err) return cb(err);

		const { request_id } = d;

		let metrics_interval = setInterval(() => {
			pollAssemble(request_id, (err, metrics) => {
				if (err) {
					clearInterval(metrics_interval);
					return cb(err);
				}

				if (metrics["status"] === "processing") {
					return;
				}

				clearInterval(metrics_interval);
				return cb(null, metrics.data);
			});
		}, ENGINE_POLL_INTERVAL);
	});
}

export const isolateSimulation = (simulation_id, cb) => {
	callApi("POST", `/simulation/${simulation_id}`, null, null, cb);
}

export const submitForwardTest = (strategy_id, security_id, start_time, stop_time, submit_interval, cb) => {
	/* HTTP Parameters. */
	const p = {
		strategy_id,
		security_id, start_time, stop_time, submit_interval,
		"is_continuous" : true,
	};

	callApi("POST", "/simulation", p, null, cb);
}

export const submitStrategy = (strategy_id, security_id, start_time, stop_time, cb) => {
	let metrics_interval = undefined;

	/* Callback that executes after each metrics API call. */
	const rcb = (err, d) => {
		if (err?.response?.status === 404) {
			log.debug("Requested metrics, but server reported they are still processing.");
		} else if (err) {
			clearInterval(metrics_interval);
			return cb(err);
		} else {
			clearInterval(metrics_interval);
			return cb(null, d);
		}
	};

	/* HTTP Parameters. */
	const p = {
		strategy_id,
		security_id, start_time, stop_time,
		"is_continuous" : false
	};

	/* Submit strategy to API. */
	callApi("POST", "/simulation", p, null, (err, d) => {
		if (err) return cb(err);

		/* Commense metrics polling. */
		const f = async.apply(getRequestMetrics, d.request_id, rcb);
		metrics_interval = setInterval(f, ENGINE_POLL_INTERVAL);
	});
}

export const submitTemplate = (security_id, start_time, stop_time, n, template, strategy_id, cb) => {
	const p = {
		strategy_id,
		security_id, start_time, stop_time,
		"is_continuous" : false,
		"number_of_strategies" : n
	};

	callApi("POST", "/simulation", p, template, (err, d) => {
		return cb(err, d.request_id);
	});

	// const submitTemplate = (wcb) => {
	// 	const p = {
	// 		strategy_id,
	// 		security_id, start_time, stop_time,
	// 		"is_continuous" : false,
	// 		"number_of_strategies" : n
	// 	};
 //
	// 	callApi("POST", "/simulation", p, template, (err, d) => {
	// 		return cb(err, d.request_id);
	// 	});
	// };

	// const requestMetrics = (request_id, wcb) => {
	// 	callApi("GET", );
	// 	axios({
	// 		"method" : "GET",
	// "url" : `${REACT_APP_API_URL}/metric/request/${request_id}`,
	// "timeout": REACT_APP_HTTP_TIMEOUT
	// 	}).then(res => {
	// 		return wcb(undefined, res.data.request_id);
	// 	}).catch(wcb);
	// };

	// async.waterfall([submitTemplate, getRequestMetrics], cb);
}

export const stopContinuous = (request_id, cb) => {
	callApi("DELETE", `/request/${request_id}`, null, null, cb);
}

export const submitContinuousTemplate = (strategy_id, security_id, start_time, stop_time, n, submit_interval, template, cb) => {
	const p = {
		strategy_id,
		security_id, start_time, stop_time,
		submit_interval,
		"number_of_strategies" : n,
		"is_continuous" : true
	};

	callApi("POST", "/simulation", p, template, cb);
}

export const submitContinuousMutation = (strategy_id, security_id, start_time, stop_time, n, submit_interval, cb) => {
	const p = {
		strategy_id,
		security_id, start_time, stop_time,
		submit_interval,
		"number_of_strategies" : n,
		"is_continuous" : true
	};

	callApi("POST", "/simulation", p, null, cb);
}

const getRequestFromStrategy = (strategy_id, cb) => {
	callApi("GET", "/request", {strategy_id}, null, cb);
};


export const pollContinuousSimulations = (strategy_id, cb) => {
	getRequestFromStrategy(strategy_id, (err, d) => {
		if (err) return cb(err);
		else if (d.request_id === null) return cb(null, null);

		callApi("GET", `/request/${d.request_id}`, null, null, cb);
	});
}

export const buildPositionsCsv = (simulation_id, cb) => {
	callApi("GET", `/simulation/${simulation_id}/positions`, null, null, cb);
}

export const getTotalSimulations = (cb) => {
	callApi("GET", "/simulation/total", null, null, cb, true);
}
